import { WidgetConfiguration } from "@sgwt-widget/core";
import { ITransaction } from "../domain/ITransaction";
import { ITransactionDocument, readOptionTypes } from "../domain/TransactionDocument";
import { buildDocumentUrl, buildPocUrl } from "../services/DocumentService";
import { ApplicationState } from "../../esg-signature-page/states/ApplicationState";
import { Origin } from "../domain/Origin";
import { Provider } from "../domain/Provider";

export const FULL_DOCUMENT_READ = 100;

export async function selectDocument(
  document: ITransactionDocument, 
  pocId: number, 
  isPocSigned: boolean, 
  transaction: ITransaction,
  widgetConfiguration: WidgetConfiguration,
  readDocument: (documentId: number, documentUrl: string) => void,
  readDocumentCompletion: (documentId: number, completion: number) => void,
) {
  const transactionId: number = isPocSigned ? transaction.id : pocId;
  if (isPocSigned || transactionId) {
    readDocument(
      document.id,
      await buildDocumentUrl(
        widgetConfiguration,
        transactionId,
        document.id
      )
    );
    if(document.readOption === readOptionTypes.VIEW) {
      readDocumentCompletion(document.id, FULL_DOCUMENT_READ)
    }
  } else {
    readDocument(
      document.id,
      await buildPocUrl(
        widgetConfiguration,
        document.id
      )
    )
  }
}

export function isMigratedDocusignDocument(state: ApplicationState): boolean {
  return state.transactionState.transaction
    ? state.transactionState.transaction.origin === Origin.ISIGN &&
        state.transactionState.transaction.signatureProvider ===
          Provider.DOCUSIGN
    : false;
}

const getDocumentIndex = (docs: ITransactionDocument[], docId: number) => {
  let index = 0;
  for(const doc of docs) {
    if(doc.id === docId) {
      return index
    }
    index++;
  }
  return -1;
}

export const isDocumentEnabled = (docs: ITransactionDocument[], doc: ITransactionDocument, isSignable: boolean) => {
  if(!isSignable) {
    return true;
  }

  const docIndex = getDocumentIndex(docs, doc.id);
  switch (docIndex) {
    case -1:
      return false;
    case 0:
      return true;
    default:
      const previousDocument = docs[docIndex - 1];
      return previousDocument && previousDocument.readCompletion === 100;
  }
}

export function getDocumentButtonTitle(language: string): string {
  switch (language.toUpperCase()) {
    case 'FR':
      return "Veuillez lire entièrement le document précédent avant d'essayer d'accéder à ce document";
    case 'DE':
      return 'Bitte lesen Sie das vorherige Dokument vollständig durch, bevor Sie versuchen, auf dieses Dokument zuzugreifen';
    default:
      return 'Please read previous document entirely before trying to access this document';
  }
}