import {
  IWidgetConfigurationProps,
  withWidgetConfiguration,
} from "@sgwt-widget/core";
import * as PDFJS from "pdfjs-dist";
import * as PDF_VIEWER from "pdfjs-dist/web/pdf_viewer";
import { Component, h } from "preact";
import { MarkupText } from "preact-i18n";
import { connect } from "preact-redux";
import { Action } from "redux";
import { ITransactionDocument, readOptionTypes } from "../../../common/domain/TransactionDocument";
import { getWorkerUrl } from "../../../common/services/PdfService";
import {
  documentLoaded,
  exitFullscreenMode,
  printCurrentDocument,
  readDocumentCompletion,
  dispatchDownloadStatus,
  readDocument,
} from "../../actions/IDocumentAction";
import { Spinner } from "../../components/spinner/Spinner";
import { ApplicationState } from "../../states/ApplicationState";
import { FullscreenMode } from "../../states/IDocumentState";
import { css } from "../../styles";

import "./PdfReader.scss";
import { SubscriptionHandle } from "@sgwt-widget/core/lib/bus/Bus";
import { Observable } from "rxjs";
import { DocumentDownloadStatus } from "../../../common/domain/DocumentDownloadStatus";
import { buildDocumentUrl, getDocumentForDownload } from "../../../common/services/DocumentService";
import { FULL_DOCUMENT_READ, isMigratedDocusignDocument } from "../../../common/helpers/documentHelper";
import { ITransaction } from "../../../common/domain/ITransaction";
import { isTransactionValid } from "../../../common/helpers/transactionHelper";

const WORKER_DESTROYED_ERROR_MSG = "Worker was destroyed";

interface IPageInfo {
  width: number;
  height: number;
  rotate: number;
}

interface IPdfReaderState {
  documentUrl: string;
  pagesCount: number;
  scale: number;
  pages: any[];
  pdfDocument: any;
  isFullscreenEnabled: boolean;
  pageInfo: IPageInfo;
  loading: boolean;
  inError: boolean;
  loadDocumentPromise: any;
}

export interface IPdfReaderProps {
  scale: number;
  language: string;
  signable: boolean;
  documentId: number;
  isPrinting: boolean;
  documentUrl: string;
  isPocSigned: boolean;
  transactionId: number;
  documentFileName: string;
  transaction: ITransaction;
  isDocumentLoaded: boolean;
  isValidSignatory: boolean;
  isValidValidator: boolean;
  fullscreenEnabled: boolean;
  transactionSigned: boolean;
  documentReadCompletion: number;
  documents: ITransactionDocument[];
  isMigratedDocusignDocument: boolean;
  downloadStatus: DocumentDownloadStatus;
  exitFullscreen: () => void;
  currentDocumentHasBeenPrinted: () => void;
  documentLoaded: (loaded: boolean, pagesCount: number) => void;
  readDocument: (documentId: number, documentUrl: string) => void;
  readDocumentCompletion: (documentId: number, completion: number) => void;
  dispatchDownloadStatus: (downloadStatus: DocumentDownloadStatus) => void;
}

const PdfReader = withWidgetConfiguration(
  class extends Component<
    IPdfReaderProps & IWidgetConfigurationProps,
    IPdfReaderState
  > {
    private container: HTMLDivElement;
    private pdfContainer: HTMLDivElement;
    private printContainer: HTMLDivElement;
    private getBearerFromWidgetConfigurationBus: SubscriptionHandle;

    public async componentWillMount() {
      if (!PDFJS.GlobalWorkerOptions.workerSrc) {
        PDFJS.GlobalWorkerOptions.workerSrc = await getWorkerUrl(
          this.props.widgetConfiguration
        );
      }
    }

    public async componentDidMount() {
      this.setState({
        documentUrl: this.props.documentUrl,
        loading: false,
        scale: this.props.scale,
      });
      this.pdfContainer.addEventListener("scroll", this.scrollHandler);
      this.loadDocument(this.props.documentUrl);
    }

    public async componentWillReceiveProps(nextProps: IPdfReaderProps) {
      if (nextProps.scale && this.state.scale !== nextProps.scale) {
        this.setDocumentScale(nextProps.scale);
      }
      this.checkDocumentToLoad(nextProps);
      this.checkFullscreenState(nextProps);
      if (nextProps.isPrinting) {
        this.print();
      }

      if (!!nextProps.downloadStatus) {
        this.downloadHandler(nextProps.downloadStatus);
      }
    }

    public checkFullscreenState(nextProps: IPdfReaderProps) {
      if (
        nextProps.fullscreenEnabled &&
        this.state.isFullscreenEnabled !== nextProps.fullscreenEnabled
      ) {
        this.setState({ isFullscreenEnabled: true });
      } else if (
        !nextProps.fullscreenEnabled &&
        this.state.isFullscreenEnabled !== nextProps.fullscreenEnabled
      ) {
        this.setState({ isFullscreenEnabled: false });
      }
    }

    public checkDocumentToLoad(nextProps: IPdfReaderProps) {
      if (
        (nextProps.documentUrl &&
          this.state.documentUrl !== nextProps.documentUrl) ||
        !nextProps.isDocumentLoaded
      ) {
        this.setState({
          documentUrl: nextProps.documentUrl,
        });
        this.loadDocument(nextProps.documentUrl);
      }
    }

    public async componentWillUnmount() {
      this.cleanCurrentContainer(this.container);
      this.pdfContainer.removeEventListener("scroll", this.scrollHandler);
      if (this.getBearerFromWidgetConfigurationBus) {
        this.props.widgetConfiguration.bus.unsubscribe(
          this.getBearerFromWidgetConfigurationBus
        );
      }
    }

    public print() {
      this.props.exitFullscreen();
      const scale = 297 / 210; // For A4 format
      const printedPages: any[] = [];
      const allPromises: Array<Promise<void>> = [];
      for (let i = 0; i < this.state.pagesCount; i++) {
        // One-by-one load pages
        const getPagePromise = this.renderDocument(
          this.printContainer,
          scale,
          i
        ).then((pdfPageView: any) => {
          printedPages.push(pdfPageView);
        });
        allPromises.push(getPagePromise);
      }
      Promise.all(allPromises).then(() => {
        const { iframe, container } = this.preparePrint();

        this.buildImageFromCanvas(iframe, container);
        setTimeout(() => {
          if (iframe) {
            iframe.focus();
            if (iframe.contentWindow) {
              iframe.contentWindow.print();
            }
            printedPages.forEach((page: any) => page.destroy());
            document.body.removeChild(iframe);
            this.props.currentDocumentHasBeenPrinted();
          }
        });
      });
    }

    private getPdfReaderClass(): string {
      const isSignable = isTransactionValid(
        this.props.signable,
        this.props.transaction,
        this.props.isValidSignatory,
        this.props.isPocSigned,
        this.props.transactionSigned
      );
      if(isSignable) {
        return this.state.isFullscreenEnabled
          ? "to-sign-pdf-reader-fullscreen"
          : "to-sign-pdf-reader";
      }
      return this.state.isFullscreenEnabled
        ? "signed-pdf-reader-fullscreen"
        : "signed-pdf-reader"
    }

    public render(): JSX.Element {
      if (this.state.inError) {
        return (
          <article className={`
              ${css("border-top border-primary")}
              ${this.getPdfReaderClass()}
            `}
          >
            <h5>
              <MarkupText id={this.props.language + ".pdf-reader.error"}>
                Cannot read document
              </MarkupText>
            </h5>
          </article>
        );
      }
      return (
        <article
          className={`
            ${this.state.loading ? "" : css("border")}
            ${this.getPdfReaderClass()}`}
        >
          <div
            className={`pdf-reader ${
              this.state.loading ? "" : css("border-primary")
            }`}
            ref={(c: HTMLDivElement) => {
              this.pdfContainer = c;
            }}
          >
            <div
              ref={(c: HTMLDivElement) => {
                this.container = c;
              }}
              className={`${css("h-100")} ${
                this.state.isFullscreenEnabled ? "fullscreen" : ""
              }`}
            >
              <Spinner loading={this.state.loading} />
            </div>
            <div
              id={"print-container"}
              ref={(c: HTMLDivElement) => {
                this.printContainer = c;
              }}
              className={css("d-none")}
            />
          </div>
        </article>
      );
    }

    private readonly scrollHandler = () => {
      const readCompletion = Math.round(
        (this.pdfContainer.scrollTop /
          (this.pdfContainer.scrollHeight - this.pdfContainer.clientHeight)) *
        100
      );
      const updatedReadCompletion = readCompletion > FULL_DOCUMENT_READ ? FULL_DOCUMENT_READ : readCompletion;
      this.props.readDocumentCompletion(this.props.documentId, updatedReadCompletion);
      this.moveToNextDocument(updatedReadCompletion);
    };

    private async moveToNextDocument(currentDocumentReadCompletion: number) {
      const isSignable = isTransactionValid(
        this.props.signable, 
        this.props.transaction, 
        this.props.isValidSignatory,
        this.props.isPocSigned, 
        this.props.transactionSigned
      );
      if(isSignable) {
        const notCompletlyReadDocuments = this.props.documents
          .filter(document => !document.readCompletion || document.readCompletion < FULL_DOCUMENT_READ);
        const currentDocument = this.props.documents.find(document => document.id === this.props.documentId);
      
        if(currentDocument && currentDocument.readOption === readOptionTypes.VIEW) {
          this.props.readDocumentCompletion(this.props.documentId, FULL_DOCUMENT_READ);
        } 
      
        if(currentDocumentReadCompletion === FULL_DOCUMENT_READ && notCompletlyReadDocuments.length !== 0) {
          const nextDocumentId = notCompletlyReadDocuments[0].id;
          this.props.readDocument(
            nextDocumentId,
            await buildDocumentUrl(
              this.props.widgetConfiguration,
              this.props.transactionId,
              nextDocumentId,
            )
          );
        }
      }
    }

    private buildImageFromCanvas(
      iframe: any,
      container: HTMLDivElement | undefined
    ) {
      if (this.printContainer) {
        for (let i = 0; i < this.state.pagesCount; i++) {
          const image: HTMLImageElement = iframe.contentWindow.document.createElement(
            "img"
          );
          const page = document.querySelector(`div[aria-label="Page ${i}"]`)
          if(page) {
            const wrapper = page.querySelector('.canvasWrapper')
            if(wrapper) {
              image.src = (wrapper.querySelector('canvas') as HTMLCanvasElement).toDataURL();
            }
          }
          if (container && image) {
            container.appendChild(image);
          }
        }
      }
    }

    private getPageSize() {
      const width = Math.round((this.state.pageInfo.width * 25.4) / 72);
      const height = Math.round((this.state.pageInfo.height * 25.4) / 72);
      return this.state.pageInfo.rotate % 180 === 0
        ? `${width}mm ${height}mm`
        : `${height}mm ${width}mm`;
    }

    private preparePrint() {
      const iframe = document.createElement("iframe");
      iframe.id = "printing-iframe";
      iframe.style.width = "0";
      iframe.style.height = "0";
      iframe.style.border = "none";
      document.body.appendChild(iframe);
      let container: HTMLDivElement | undefined;
      if (iframe.contentWindow) {
        iframe.contentWindow.document.write("<body></body>");
        const style = iframe.contentWindow.document.createElement("style");
        style.appendChild(
          document.createTextNode(
            `@page {size: ${this.getPageSize()}; margin:0;}`
          )
        );
        iframe.contentWindow.document.head.appendChild(style);
        container = iframe.contentWindow.document.createElement("div");
        iframe.contentWindow.document.body.appendChild(container);
      }
      return { iframe, container };
    }

    private setDocumentScale(scale: number) {
      this.setState({ scale });
      if (this.state.pages && this.state.pages.length > 0) {
        for (let i = 0; i < this.state.pagesCount; i++) {
          this.renderDocument(this.container, this.state.scale, i).then(
              (pdfPageView: any) => {
                this.state.pages.push(pdfPageView);
          })
        }
      }
    }

    private loadDocument(documentUrl: string) {
      if (!documentUrl || !this.container) {
        return;
      }
      if (!this.state.pages) {
        this.setState({ pages: [] });
      }
      const { container } = this;
      this.cleanCurrentContainer(container);
      if (this.state.loadDocumentPromise) {
        this.state.loadDocumentPromise.destroy().then(() => {
          this.downloadAndRenderDocumentContent(documentUrl);
        });
      } else {
        this.downloadAndRenderDocumentContent(documentUrl);
      }
    }

    private downloadAndRenderDocumentContent(documentUrl: string) {
      const { container } = this;

      if (this.props.isMigratedDocusignDocument) {
        this.setState({
          inError: true,
          loading: false,
        });
        return;
      }
      const loadDocumentPromise = PDFJS.getDocument({
        httpHeaders: this.buildHeadersWithToken(),
        url: documentUrl,
      });
      this.setState({
        inError: false,
        loadDocumentPromise,
        loading: true,
      });
      loadDocumentPromise.promise
        .then((doc: any) => {
          this.setState({
            pagesCount: doc.numPages,
            pdfDocument: doc,
          });
          const promises = [];
          for (let i = 0; i < doc.numPages; i++) {
            // One-by-one load pages
            promises.push(
              this.renderDocument(container, this.state.scale, i).then(
                (pdfPageView: any) => {
                  this.state.pages.push(pdfPageView);
                }
              )
            );
          }
          return promises;
        })
        .then(() => {
          this.setState({ loading: false });
          this.props.documentLoaded(true, this.state.pagesCount);
          if (
            this.props.documentReadCompletion &&
            this.props.documentReadCompletion !== FULL_DOCUMENT_READ
          ) {
            setTimeout(() => {
              this.pdfContainer.scrollTo({
                behavior: "smooth",
                top:
                  ((this.pdfContainer.scrollHeight -
                    this.pdfContainer.clientHeight) *
                    this.props.documentReadCompletion) /
                  100,
              });
            }, 100);
          }
        })
        .catch((err: Error) => {
          console.error(err);
          this.setState({
            inError: err.message.indexOf(WORKER_DESTROYED_ERROR_MSG) !== 0,
            loading: false,
          });
        });
    }

    private buildPublicToken() {
      const currentUrl: string = window.location.href;
      if (currentUrl.includes("/p/")) {
        const urlParam = currentUrl.split("/p/");
        const token = urlParam[1].split("/")[0];
        return { Token: token };
      }
      return null;
    }

    private buildSsoToken(): Observable<string> {
      return new Observable(observer => {
        this.getBearerFromWidgetConfigurationBus = this.props.widgetConfiguration.bus
          .subscribe<string>("sg-connect.access-token", async (bearer: string | undefined) => {
          if (bearer) {
            observer.next(bearer);
            observer.complete();
          }
        });
      });
    }

    private buildHeadersWithToken() {
      if (this.buildPublicToken() === null) {
        let bearerToken = "";
        this.buildSsoToken().subscribe(bearer => (bearerToken = bearer));
        return { Authorization: bearerToken };
      }
      return this.buildPublicToken();
    }

    private renderDocument(
      container: HTMLElement | undefined,
      scale: number,
      pageNumber: number
    ): Promise<any> {

      if (container) {
        container.innerHTML = "";
      }

      return this.state.pdfDocument
        .getPage(pageNumber + 1)
        .then((pdfPage: any) => {
          // Add div with page view.
          const pdfPageView = new PDF_VIEWER.PDFPageView({
            container,
            defaultViewport: pdfPage.getViewport(scale),
            id: pageNumber,
            scale,
          });

          // Associates the actual page with the view, and drawing it
          pdfPageView.setPdfPage(pdfPage);

          pdfPageView.draw()
            .catch((err: Error) => {
              console.error('renderDocument draw error: ', err);
            });

          if (pageNumber === 0) {
            const [
              horizontalMargin,
              verticalMargin,
              width,
              height,
            ] = pdfPageView.pdfPage._pageInfo.view;
            this.setState({
              pageInfo: {
                height: height - verticalMargin,
                rotate: pdfPageView.pdfPage.rotate,
                width: width - horizontalMargin,
              },
            });
          }
          return pdfPageView;
          
        });
    }

    private cleanCurrentContainer(container: HTMLDivElement | undefined) {
      if (this.state.pages && this.state.pages.length > 0) {
        this.state.pages.forEach((pdfPageView: any) => {
          pdfPageView.destroy();
        });
        this.state.pages.length = 0;
      }
      if (this.state.pdfDocument) {
        this.state.pdfDocument.destroy();
      }
      if (container) {
        container.innerHTML = "";
      }
    }

    private downloadHandler(downloadStatus: DocumentDownloadStatus) {
      switch (downloadStatus) {
        case DocumentDownloadStatus.REQUESTED: {
          if (this.props.isMigratedDocusignDocument) {
            this.downloadMigratedDocumentForDocusign();
          } else {
            this.downloadDocument();
          }
        }
      }
    }

    private downloadMigratedDocumentForDocusign() {
      this.props.dispatchDownloadStatus(DocumentDownloadStatus.DOWNLOADING);
      getDocumentForDownload(
        this.props.widgetConfiguration,
        this.props.documentUrl
      )
        .then((blob: any) => {
          if (window.navigator && window.navigator.msSaveBlob) {
            window.navigator.msSaveBlob(blob, this.props.documentFileName);
          } else {
            const link = document.createElement("a");
            link.href = (window.URL || window.webkitURL).createObjectURL(blob);
            link.download = this.props.documentFileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }

          this.props.dispatchDownloadStatus(DocumentDownloadStatus.DOWNLOADED);
        })
        .catch(() => {
          this.props.dispatchDownloadStatus(DocumentDownloadStatus.ERROR);
        });
    }

    private downloadDocument() {
      this.props.dispatchDownloadStatus(DocumentDownloadStatus.DOWNLOADING);
      this.state.pdfDocument
        .getData()
        .then((arrayData: { data: any }) => {
          const blob = new Blob([arrayData], { type: "application/pdf" });

          if (window.navigator && window.navigator.msSaveBlob) {
            window.navigator.msSaveBlob(blob, this.props.documentFileName);
          } else {
            const link = document.createElement("a");
            link.href = (window.URL || window.webkitURL).createObjectURL(blob);
            link.download = this.props.documentFileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }

          this.props.dispatchDownloadStatus(DocumentDownloadStatus.DOWNLOADED);
        })
        .catch(() => {
          this.props.dispatchDownloadStatus(DocumentDownloadStatus.ERROR);
        });
    }
  }
);

function mapStateToProps(state: ApplicationState) {
  return {
    scale: state.documentState.scale,
    language: state.languageState.language,
    signable: state.signatureState.signable,
    documentId: state.documentState.currentDocumentId,
    isPrinting: state.documentState.isPrinting,
    documentUrl: state.documentState.currentDocumentUrl,
    isPocSigned: state.pocState.isPocSigned,
    transactionId: state.transactionIdState.transactionId,
    documentFileName: (() => {
      const currentDocument = getCurrentDocument(state);
      return currentDocument ? currentDocument.filename : "";
    })(),
    transaction: state.transactionState.transaction,
    isDocumentLoaded: state.documentState.loaded,
    isValidSignatory: state.userState.isValidSignatory,
    isValidValidator: state.userState.isValidValidator,
    fullscreenEnabled: state.documentState.fullscreenMode === FullscreenMode.ON,
    transactionSigned: state.signatureState.signed,
    documentReadCompletion: (() => {
      const currentDocument = getCurrentDocument(state);
      return currentDocument ? currentDocument.readCompletion : 0;
    })(),
    documents: state.documentState.documents,
    isMigratedDocusignDocument: isMigratedDocusignDocument(state),
    downloadStatus: state.documentState.downloadStatus,
  };
}

function getCurrentDocument(state: ApplicationState) {
  return state.documentState.documents.find(
    (doc: ITransactionDocument) =>
      doc.id === state.documentState.currentDocumentId
  );
}

const mapDispatchToProps = (dispatch: (action: Action) => void) => ({
  exitFullscreen: () => dispatch(exitFullscreenMode()),
  currentDocumentHasBeenPrinted: () => dispatch(printCurrentDocument(false)),
  documentLoaded: (loaded: boolean, pagesCount: number) =>
    dispatch(documentLoaded(loaded, pagesCount)),
  readDocument: (documentId: number, documentUrl: string) =>
    dispatch(readDocument(documentId, documentUrl)),
  readDocumentCompletion: (documentId: number, completion: number) =>
    dispatch(readDocumentCompletion(documentId, completion)),
  dispatchDownloadStatus: (downloadStatus: DocumentDownloadStatus) => 
    dispatch(dispatchDownloadStatus(downloadStatus)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PdfReader as any);
