import { Component, h } from "preact";
import { MarkupText } from "preact-i18n";
import FileSelector from "../../file-selector/FileSelector";
import ZoomComponent from "../../zoom-component/ZoomComponent";
import { css } from "../../../styles";
import FullscreenButton from "../../fullscreen-button/FullscreenButton";
import PrintButton from "../../print-button/PrintButton";
import DownloadButton from "../../download-button/DownloadButton";
import PdfReader from "../../pdf-reader/PdfReader";
import OverallContractsReview from "../../overall-contracts-review/OverallContractsReview";
import { ITransaction } from "../../../../common/domain/ITransaction";
import { getContractName } from "../../../../common/services/SignatureWorkflow";

interface IDocumentViewerComponentProps {
  language: string,
  isPocSigned: boolean,
  transaction: ITransaction,
  setDocumentContainer: (elt: HTMLElement) => void,
}

export class DocumentViewerComponent extends Component<IDocumentViewerComponentProps, {}> {
  private renderContractType(): JSX.Element {
    if (this.props.transaction) {
      if (this.props.transaction.contractType && !this.props.transaction.contractType.exclusiveSignatureWorkflow) {
        return (
          <div className={css("row py-1 d-lg-block d-none")}>
            <h1 className={css("display-3 pl-3")}>
              {getContractName(this.props.transaction)}
            </h1>
          </div>
        );
      } else if (!this.props.isPocSigned) {
        return (
          <div className={css("row py-1 d-lg-block d-none")}>
            <h1 className={css("display-3 pl-3")}>
              <MarkupText id={this.props.language + "."}>
                Proof of consent
              </MarkupText>
            </h1>
          </div>
        );
      }
    }
    return <div />;
  }

  public render(): JSX.Element {
    return (
      <section
        ref={(elt: HTMLElement) => {
          this.props.setDocumentContainer(elt);
        }}
        id="pdf-with-control-container"
      >
        <section>
          {this.renderContractType()}
          <section className={css("row p-1")}>
            <article className={css("col-sm-4 mt-3 mt-sm-0 align-self-left")}>
              <div className={css("row justify-content-center justify-content-sm-start")}>
                <FileSelector />
              </div>
            </article>
            <article className={`${css("col-sm-4 mt-3 mt-sm-0 align-self-center")} not-mobile-article`}>
              <div className={css("row justify-content-center")}>
                <ZoomComponent />
              </div>
            </article>
            <article
              className={`${css("col-sm-4 mt-3 mt-sm-0 align-self-end")} not-mobile-article`}>
              <div className={css("row justify-content-center justify-content-sm-end")}>
                <FullscreenButton />
                <PrintButton />
                <DownloadButton />
              </div>
            </article>
            <article
              className={`${css("col-sm-12 mt-3 mt-sm-0 align-self-center")} mobile-article`}>
              <div className={css("row justify-content-center justify-content-sm-center")}>
                <ZoomComponent />
                <FullscreenButton />
                <PrintButton />
                <DownloadButton />
              </div>
            </article>
          </section>
          <section className={`${css("row")}`}>
            <article className={`${css("w-100 px-3")}`}>
              <PdfReader />
            </article>
          </section>
        </section>
        <section className={`${css("col py-3")} mobile-article`}>
          <OverallContractsReview />
        </section>
      </section>
    )
  }
}