import { IWidgetConfigurationProps, withWidgetConfiguration } from "@sgwt-widget/core";
import { Component, h } from "preact";
import { MarkupText } from "preact-i18n";
import { connect } from "preact-redux";
import { Action } from "redux";
import { ITransaction } from "../../../common/domain/ITransaction";
import { ITransactionDocument } from "../../../common/domain/TransactionDocument";
import { readDocument, readDocumentCompletion } from "../../actions/IDocumentAction";
import { ApplicationState } from "../../states/ApplicationState";
import { css } from "../../styles";
import "./ContractsReview.scss";
import { FULL_DOCUMENT_READ, getDocumentButtonTitle, isDocumentEnabled, selectDocument } from "../../../common/helpers/documentHelper";
import { ProgressBarComponent } from "../../components/progress-bar/ProgressBar";
import { isTransactionValid } from "../../../common/helpers/transactionHelper";

interface IContractsReviewState {
  language: string;
  collapsed: boolean;
  readDocuments: number;
  transaction: ITransaction;
  documents: ITransactionDocument[];
}

export interface IContractsReviewProps {
  pocId: number;
  language: string;
  signable: boolean;
  documentId: number;
  isPocSigned: boolean;
  transaction: ITransaction;
  isValidSignatory: boolean;
  isValidValidator: boolean;
  transactionSigned: boolean;
  documents: ITransactionDocument[];
  readDocument: (documentId: number, documentUrl: string) => void;
  readDocumentCompletion: (documentId: number, completion: number) => void;
}

const ContractsReview = withWidgetConfiguration(
  class extends Component<IContractsReviewProps & IWidgetConfigurationProps,
    IContractsReviewState> {
    public async componentDidMount() {
      if (this.props.documents) {
        this.setState({
          documents: this.props.documents,
          readDocuments: this.props.documents.filter(
            (doc: ITransactionDocument) => doc.readCompletion === FULL_DOCUMENT_READ
          ).length,
        });
      }
      if (this.props.language) {
        this.setState({ language: this.props.language });
      }
      this.setState({ collapsed: false });
    }

    public async componentWillReceiveProps(nextProps: IContractsReviewProps) {
      if (nextProps.documents) {
        this.setState({
          documents: nextProps.documents,
          readDocuments: nextProps.documents.filter(
            (doc: ITransactionDocument) => doc.readCompletion === FULL_DOCUMENT_READ
          ).length,
        });
      }
      if (nextProps.language) {
        this.setState({ language: nextProps.language });
      }
      if(nextProps.transaction) {
        this.setState({transaction: nextProps.transaction});
      }
    }

    public async componentDidUpdate() {
      this.scrollCurrentDocumentToView();
    }

    private renderTitle(): JSX.Element {
      if (this.props.isValidSignatory) {
        return (
          <span className={css('align-top')} style={{ fontSize: 16, display: "contents" }}>
            <MarkupText
              id={this.state.language + ".contracts.title"}
              fields={{
                done: this.state.readDocuments,
                total: this.state.documents ? this.state.documents.length : 0,
              }}
            >
              Contracts review
            </MarkupText>
          </span>
        );
      }
      return (
        <span className={css('align-top')} style={{ fontSize: 16, display: "contents" }}>
          <MarkupText id={this.state.language + ".contracts.title-no-fields"}>
            Contracts review
          </MarkupText>
        </span>
      );
    }

    private scrollCurrentDocumentToView() {
      const documentTitle = document.getElementById(`contrat-review-document-id-${this.props.documentId}`);
      if(documentTitle) {
        documentTitle.scrollIntoView();
      }
    }

    private renderMandatoryDocuments(): JSX.Element[] {
      const isSignable = isTransactionValid(
        this.props.signable, 
        this.props.transaction, 
        this.props.isValidSignatory,
        this.props.isPocSigned, 
        this.props.transactionSigned
      );
      return this.props.documents.map(document => {
        if (this.isUserCanSign() && this.props.signable && document.mustRead) {
          const isDocumentDisabled = !isDocumentEnabled(this.props.documents, document, isSignable);
          return (
            <section className={css("mb-3")}>
              <article className={"alignContracts"}>
                <h6 className={css("mb-2")}>
                  <button
                    id={`contrat-review-document-id-${document.id}`}
                    className={`${css("btn btn-flat-primary")} pointer`}
                    style={{ whiteSpace: "normal", textAlign: "left" }}
                    disabled={isDocumentDisabled}
                    title={isDocumentDisabled ? getDocumentButtonTitle(this.props.language) : ''}
                    onClick={() => selectDocument(document,this.props.pocId,
                      this.props.isPocSigned,
                      this.state.transaction,
                      this.props.widgetConfiguration,
                      this.props.readDocument,
                      this.props.readDocumentCompletion)}
                  >
                    { 
                      document.id === this.props.documentId
                      ? <strong>{document.readCompletion || 0}% {document.filename}</strong>
                      : <span>{document.readCompletion || 0}% {document.filename}</span>
                    }
                  </button>
                </h6>
                <ProgressBarComponent readCompletion={document.readCompletion || 0}/>
              </article>
            </section>
          );
        }
        return (
          <article className={`${css("mb-2")} alignContracts`}>
            <button
              className={`${css("btn btn-flat-primary")} pointer`}
              style={{ whiteSpace: "normal", textAlign: "left" }}
              onClick={() => selectDocument(document,
                this.props.pocId,
                this.props.isPocSigned,
                this.state.transaction,
                this.props.widgetConfiguration,
                this.props.readDocument,
                this.props.readDocumentCompletion)}
            >
              {document.filename}
            </button>
          </article>
        );
      });
    }

    private isUserCanSign() {
      return this.props.isValidSignatory || this.props.isValidValidator || !this.props.isPocSigned;
    }

    public render(): JSX.Element {
      return (
        <section id="accordion" className={css("mb-3")}>
          <div className={css("card mb-2 bg-lvl2")}>
            <div className={css("pl-0 card-header")} id="contractsSection">
              <h5 className={css("mb-0")}>
                <button
                  className={`${css("btn btn-lg btn-flat-primary justify-content-start pl-0 ml-2 d-block")} pointer`}
                  data-toggle={css("collapse")}
                  data-target="#contracts"
                  aria-expanded="false"
                  aria-controls="contracts"
                  onClick={() =>
                    this.setState({ collapsed: !this.state.collapsed })
                  }
                >
                  <em className={"material-icons icon mr-2"}>
                    {this.state.collapsed ? 'keyboard_arrow_right' : 'expand_more'}
                  </em>
                  {this.renderTitle()}
                </button>
                <section
                  id="contracts"
                  className={css("collapse", this.state.collapsed ? "" : "show")}
                  aria-labelledby="contractsSection"
                  data-parent="#accordion"
                >
                  {this.renderMandatoryDocuments()}
                </section>
              </h5>
            </div>
          </div>
        </section>
      );
    }
  }
);

function mapStateToProps(state: ApplicationState) {
  return {
    pocId: state.pocState.pocId,
    language: state.languageState.language,
    signable: state.signatureState.signable,
    documentId: state.documentState.currentDocumentId,
    isPocSigned: state.pocState.isPocSigned,
    transaction: state.transactionState.transaction,
    isValidSignatory: state.userState.isValidSignatory,
    isValidValidator: state.userState.isValidValidator,
    transactionSigned: state.signatureState.signed,
    documents: state.documentState.documents,
  };
}

const mapDispatchToProps = (dispatch: (action: Action) => void) => ({
  readDocument: (documentId: number, documentUrl: string) =>
    dispatch(readDocument(documentId, documentUrl)),
  readDocumentCompletion: (documentId: number, completion: number) =>
    dispatch(readDocumentCompletion(documentId, completion)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContractsReview as any);
