import { IWidgetConfigurationProps, withWidgetConfiguration } from "@sgwt-widget/core";
import { Component, h } from "preact";
import { ApplicationState } from "../../states/ApplicationState";
import { Action } from "redux";
import { connect } from "preact-redux";
import { acceptConsent, allDocumentsRead, errorConsent, transactionSigned, updateStep } from "../../actions/ISignatureAction";
import { ITransaction } from "../../../common/domain/ITransaction";
import { ISignatureWorkflow, ISignatureWorkflowStep } from "../../../common/domain/ISignatureWorkflow";
import { css } from "../../styles";
import { sign } from "../../../common/services/SignatureService";
import { backToTop, getOtpIndex } from "../../../common/services/SignatureWorkflow";
import { IChannel } from "../../../common/domain/IChannel";
import { isUserValidSignatory } from "../../../common/services/UserService";
import { IUser } from "../../../common/domain/User";
import { IProofOfConsent } from "../../../common/domain/IProofOfConsent";
import { currentUserIsSignatory, setCurrentUserPhoneNumber, transactionUpdated } from "../../actions";
import { Alert } from "../../components/alert/Alert";
import { registerConsent } from "../../../common/services/ConsentSevice";
import WorkflowStepper from "./WorkflowStepper";
import { WorkflowButton } from "./WorkflowButton";
import { isSpiderCollateralChecked } from "../../../common/helpers/collateralHelper";

interface IState {
  proofOfConsent: IProofOfConsent;
  signatureStatus: { validated: boolean, error: boolean };
  isSignaturePending: boolean;
}

export interface IWorkflowPaginationProps {
  language: string;
  signable: boolean;
  signatureProcessing: boolean;
  isValidSignatory: boolean;
  isPocSigned: boolean;
  transactionSigned: boolean;
  isOtpMandatory: boolean;
  allDocumentsRead: boolean;
  documentsValidation: boolean;
  transactionId: number;
  channel: IChannel;
  user: IUser;
  isOTPValid: boolean;
  transaction: ITransaction;
  consents: Map<number, string[]>;
  signatureWorkflow: ISignatureWorkflow;
  step: number;
  isCertificatePending: boolean;
  currentUserIsSignatory: (valid: boolean) => void;
  setCurrentUserPhoneNumber: (phoneNumber?: string) => void;
  acceptConsent: (consent: string | null) => void;
  allDocumentsAreRead: (areRead: boolean) => void;
  isTransactionSigned: (isSigned: boolean, transaction: ITransaction) => void;
  errorConsent: (isInError: boolean) => void;
  updateStep: (step: number) => void;
}

const WorkflowPagination = withWidgetConfiguration(
  class extends Component<IWorkflowPaginationProps & IWidgetConfigurationProps, IState> {
    private otpStepIndex = -1;
    private readonly PREVIOUS_ICON_ORDER = 0;
    private readonly GO_TO_PREVIOUS_PAGE = -1;
    private readonly NEXT_ICON_ORDER = 1;
    private readonly GO_TO_NEXT_PAGE = 1;

    public async componentDidMount() {
      this.setState({
        signatureStatus: { validated: false, error: false },
        isSignaturePending: false
      });
    }

    private setOtpData(): void {
      if (this.props.signatureWorkflow) {
        this.otpStepIndex = getOtpIndex(this.props.signatureWorkflow.languages);
      }
    }

    public isSignable() {
      return this.props.signable && this.props.allDocumentsRead;
    }

    private isPrevDisabled(): boolean {
      return this.props.step === 0;
    }

    private areAllStepConsentsValidated(
      step: number,
      currentStep: ISignatureWorkflowStep
    ): boolean {
      const consents = this.props.consents.get(step);
      if (currentStep.checkbox) {
        return (consents && consents.length === currentStep.checkbox.length) as boolean;
      }
      if (currentStep.collateral) {
        return isSpiderCollateralChecked(
          consents,
          this.props.transaction.spiderCollateralOption,
          currentStep.collateral
        );
      }
      return true;
    }

    private checkReadDocuments(currentStep: ISignatureWorkflowStep) {
      if (currentStep.documentViewer) {
        return this.isSignable();
      }
      return true;
    }

    private checkDocumentList(currentStep: ISignatureWorkflowStep) {
      if (currentStep.documentList) {
        return this.props.documentsValidation;
      }
      return true;
    }

    private checkOTP(currentStep: ISignatureWorkflowStep) {
      if (currentStep.OTP) {
        return this.props.isOTPValid;
      }
      return true;
    }

    private isNextDisabled(): boolean {
      if (
        this.props.signatureWorkflow &&
        this.props.signatureWorkflow.languages
      ) {
        const step = this.props.step;
        const currentStep = this.props.signatureWorkflow.languages.fr[step];

        return (
          !this.areAllStepConsentsValidated(step, currentStep) ||
          !this.checkReadDocuments(currentStep) ||
          !this.checkDocumentList(currentStep) ||
          !this.checkOTP(currentStep)
        );
      }
      return false;
    }

    private isTransactionValid(): boolean {
      return this.props.signable
        && (!this.props.transaction.deadline || new Date(this.props.transaction.deadline).getTime() > new Date().getTime())
        && (this.props.isValidSignatory || !this.props.isPocSigned)
        && !this.props.transactionSigned
        && this.props.isOtpMandatory
    }

    public async signTransaction() {
      this.setState({
        signatureStatus: { validated: false, error: false },
        isSignaturePending: true
      });
      const transactionId: number = this.props.transactionId;

      if (
        this.props.allDocumentsRead &&
        !this.props.signatureProcessing &&
        this.props.isOTPValid
      ) {
        if (this.props.consents) {
          this.props.consents.forEach(consent => {
            consent.forEach(elt => {
              registerConsent(
                this.props.widgetConfiguration,
                this.props.transactionId,
                this.props.channel,
                elt
              );
            })
          });
        }

        sign(
          this.props.widgetConfiguration,
          transactionId,
          this.props.channel,
          this.props.language
        ).then((transaction: ITransaction) => {
          const { isSignatory, phoneNumber } = isUserValidSignatory(this.props.user, transaction);
          this.props.currentUserIsSignatory(isSignatory);
          this.props.setCurrentUserPhoneNumber(phoneNumber);
        })
          .then(() => {
            if (this.props.isValidSignatory) {
              this.props.acceptConsent(null);
              this.props.allDocumentsAreRead(false);
            }
            this.props.isTransactionSigned(true, this.props.transaction);
            this.setState({
              signatureStatus: { validated: true, error: false },
              isSignaturePending: false
            })
          })
          .catch(() => {
            this.props.isTransactionSigned(false, this.props.transaction);
            this.setState({
              signatureStatus: { validated: false, error: true },
              isSignaturePending: false
            })
          });
      } else {
        this.props.errorConsent(true);
      }
    }

    private stepManagementCount(counter: number): void {
      const step = this.props.step;
      if (counter === -1 && step === 0) {
        this.props.updateStep(0);
      } else if (counter === 1 && step === this.otpStepIndex) {
        this.signTransaction()
      } else {
        this.props.updateStep(step + counter);
        backToTop();
      }
    }

    private canDisplayAlert(): boolean {
      return !!this.state.signatureStatus && this.state.signatureStatus.error
        && this.getAlertMessage().messageId != undefined;
    }

    private getAlertMessage(): { isSuccess: boolean, messageId: string } {
      if (!!this.state.signatureStatus && this.state.signatureStatus.error) {
        return {
          isSuccess: false,
          messageId: this.props.language + ".signature.invalid-signature",
        }
      } else {
        return {
          isSuccess: false,
          messageId: "",
        }
      }
    }

    public render(): JSX.Element {
      const isLastStep = this.otpStepIndex === this.props.step;
      const isSpinning = this.props.isCertificatePending || this.state.isSignaturePending;
      this.setOtpData();
      if (this.isTransactionValid()) {
        return (
          <section className={css("row pl-3 pr-3")}>
            <section className={css("col")}>
              <div className={css("row mt-2")}>
                <div className={css("col d-inline-flex p-2")} style={"background-color: #f7f9fa;"}>

                  <WorkflowButton 
                    isLastStep={isLastStep}
                    isDisabled={this.isPrevDisabled()}
                    isSpinning={false}
                    language={this.props.language}
                    label={".buttons.previous"}
                    class={`btn btn-outline-primary col-sm-5 col-lg-2 ${this.isPrevDisabled() ? "disabled" : ""}`}
                    icon={"chevron_left"}
                    iconOrder={this.PREVIOUS_ICON_ORDER}
                    onClick={() => this.stepManagementCount(this.GO_TO_PREVIOUS_PAGE)}
                  ></WorkflowButton>

                  <article className={css("col-sm-2 col-lg-8 d-flex align-items-center justify-content-center mx-auto")}>
                    <WorkflowStepper />
                  </article>

                  <WorkflowButton 
                    isLastStep={isLastStep}
                    isDisabled={this.isNextDisabled() || isSpinning}
                    isSpinning={isSpinning}
                    language={this.props.language}
                    label={isLastStep ? ".buttons.sign" : ".buttons.next"}
                    class={`btn ${isLastStep ? "btn-success" : "btn-primary"} col-sm-5 col-lg-2 ${this.isNextDisabled() ? "disabled" : ""}`}
                    icon={"chevron_right"}
                    iconOrder={this.NEXT_ICON_ORDER}
                    onClick={() => this.stepManagementCount(this.GO_TO_NEXT_PAGE)}
                  ></WorkflowButton>

                </div>
              </div>
              <Alert canDisplayAlert={this.canDisplayAlert()} alertMessage={this.getAlertMessage()} />
            </section>
          </section>
        )
      }
      return <div />;
    }
  });

function mapStateToProps(state: ApplicationState) {
  return {
    language: state.languageState.language,
    signable: state.signatureState.signable,
    signatureProcessing: state.signatureState.processing,
    isValidSignatory: state.userState.isValidSignatory,
    isPocSigned: state.pocState.isPocSigned,
    transactionSigned: state.signatureState.signed,
    isOtpMandatory: state.userState.isOtpMandatory,
    allDocumentsRead: state.signatureState.allDocumentsRead,
    documentsValidation: state.signatureState.documentsValidation,
    transactionId: state.transactionIdState.transactionId,
    channel: state.signatureState.channel,
    user: state.userState.user,
    isOTPValid: state.signatureState.isOTPValid,
    transaction: state.transactionState.transaction,
    consents: state.signatureState.consents,
    signatureWorkflow: state.signatureState.signatureWorkflow,
    step: state.signatureState.step,
    isCertificatePending: state.signatureState.isCertificatePending
  }
};

const mapDispatchToProps = (dispatch: (action: Action) => void) => ({
  errorConsent: (isInError: boolean) => dispatch(errorConsent(isInError)),
  updateStep: (step: number) => dispatch(updateStep(step)),
  currentUserIsSignatory: (valid: boolean, phoneNumber?: string) => dispatch(currentUserIsSignatory(valid)),
  setCurrentUserPhoneNumber: (phoneNumber?: string) => dispatch(setCurrentUserPhoneNumber(phoneNumber)),
  acceptConsent: (consent: string) => dispatch(acceptConsent(consent)),
  allDocumentsAreRead: (areRead: boolean) => dispatch(allDocumentsRead(areRead)),
  isTransactionSigned: (isSigned: boolean, transaction: ITransaction) => {
    dispatch(transactionSigned(isSigned));
    dispatch(transactionUpdated(transaction));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkflowPagination as any);
