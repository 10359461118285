import { CounterpartType } from "../domain/ISignatureCounterpart";
import { ISignatureWorkflowStep, ISignatureWorkflowLanguage, ISignatureWorkflow, IDocumentConsent } from "../domain/ISignatureWorkflow";
import { ITransaction } from "../domain/ITransaction";
import { ITransactionDocument, readOptionTypes } from "../domain/TransactionDocument";
import { isTransactionExpired, isTransactionSigned } from "../helpers/transactionHelper";

export function backToTop(): void {
  const signatureWidget = document.getElementsByClassName('esg-signature-page');
  if(signatureWidget.length > 0) {
    signatureWidget[1].scrollTop = 0;
  }
}

export function isDocumentPresentInConsentArray(docId: number, documentsConsentArray: IDocumentConsent[]): boolean {
  return !!documentsConsentArray.find((document) => document.documentId === docId);
}

export function getDocumentConsentById(docId: number, documentsConsentArray: IDocumentConsent[]): boolean {
  for (const document of documentsConsentArray) {
    if (document.documentId === docId) {
      return document.isValidate;
    }
  }
  return false;
}

export function updateDocumentConsentArray(docId: number, isChecked: boolean, documentsConsentArray: IDocumentConsent[]): void {
  for (const document of documentsConsentArray) {
    if (document.documentId === docId) {
      document.isValidate = isChecked;
    }
  };
}

export function areAllDocumentsForSignatureValidated(documentsConsentArray: IDocumentConsent[]): boolean {
  let areAllDocumentsValidated: boolean = true;
  for (const document of documentsConsentArray) {
    if (!document.isValidate) {
      areAllDocumentsValidated = false;
      break;
    }
  }
  return areAllDocumentsValidated;
}

export function getSignatureWorkflowByLanguage(language: string, signatureWorkflow: ISignatureWorkflowLanguage): ISignatureWorkflowStep[] {
  switch (language) {
    case "en":
      return signatureWorkflow["en"] != undefined ? signatureWorkflow["en"] : signatureWorkflow["fr"];
    case "de":
      return signatureWorkflow["de"] != undefined ? signatureWorkflow["de"] : signatureWorkflow["fr"];
    default:
      return signatureWorkflow["fr"];
  }
}

export function getDateFormat(language: string): string {
  switch (language) {
    case "en":
      return "en-US";
    case "de":
      return "de-DE";
    default:
      return "fr-FR"
  }
}

export function getContractName(transaction: ITransaction): string {
  if(transaction.contractName) {
    return transaction.contractName
  }
  if(transaction.contractType) {
    return transaction.contractType.name
  }
  return "";
}

export function getDocumentsForSignature(transaction: ITransaction): ITransactionDocument[] {
  return transaction.documentsForSignature.filter(document => document.readOption === readOptionTypes.READ_ALL);
}

export function getOtpIndex(signatureWorkflow: ISignatureWorkflowLanguage): number {
  let counter = -1;
  const signatureWorkflowFR = getSignatureWorkflowByLanguage("fr", signatureWorkflow)
  for (const step of signatureWorkflowFR) {
    counter++;
    if (step.OTP) {
      break;
    }
  }
  return counter;
}

function getCurrentCounterpartType(transaction: ITransaction): CounterpartType | undefined {
  const currentCounterpartOrder = transaction.currentCounterpartOrder;
  return currentCounterpartOrder ? transaction.signatureCounterparts[currentCounterpartOrder - 1].type : undefined;
}

export function hasDocumentViewer(transaction: ITransaction, signatureWorkflow: ISignatureWorkflow, step: number): boolean {
  const isSigned = isTransactionSigned(transaction);
  const isExpired = isTransactionExpired(transaction);
  const currentCounterpartType = getCurrentCounterpartType(transaction);
  if((!signatureWorkflow || currentCounterpartType === CounterpartType.CORPORATE) || isSigned || isExpired) {
    return true;
  } else {
    let isDocumentViewerPresent = false;
    if("documentViewer" in signatureWorkflow.languages.fr[step]) {
      isDocumentViewerPresent = true;
    };
    return isDocumentViewerPresent;
  }
}