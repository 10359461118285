import "./Pagination.scss";

import { Component, h } from "preact";
import { connect } from "preact-redux";
import { ITransactionDocument } from "../../../common/domain/TransactionDocument";
import { ApplicationState } from "../../states/ApplicationState";
import { FULL_DOCUMENT_READ } from "../../../common/helpers/documentHelper";

interface IPaginationState {
  currentPage?: number;
}

export interface IPaginationProps {
  pagesCount: number;
  readProgression: number;
}

class Pagination extends Component<IPaginationProps, IPaginationState> {
  public async componentDidMount() {
    this.setState({
      currentPage: this.getCurrentPage(this.props.readProgression),
    });
  }

  public async componentWillReceiveProps(nextProps: IPaginationProps) {
    this.setState({
      currentPage: this.getCurrentPage(nextProps.readProgression),
    });
  }

  public getCurrentPage(readProgression: number) {
    if (!readProgression) {
      return 1;
    }
    const currentPage = Math.ceil(
      (this.props.pagesCount * readProgression) / FULL_DOCUMENT_READ
    );
    return currentPage < this.props.pagesCount
      ? currentPage
      : this.props.pagesCount;
  }

  public render(): JSX.Element {
    if (this.state.currentPage && this.props.pagesCount) {
      return (
        <article id={"pagination"}>
          {this.state.currentPage}/{this.props.pagesCount}
        </article>
      );
    }
    return <div />;
  }
}

function mapStateToProps(state: ApplicationState) {
  return {
    pagesCount: (() => {
      const currentDocument = state.documentState.documents.find(
        (doc: ITransactionDocument) =>
          doc.id === state.documentState.currentDocumentId
      );
      return currentDocument ? currentDocument.pagesCount : 0;
    })(),
    readProgression: (() => {
      const currentDocument = state.documentState.documents.find(
        (doc: ITransactionDocument) =>
          doc.id === state.documentState.currentDocumentId
      );
      return currentDocument ? currentDocument.readCompletion : 0;
    })(),
  };
}

export default connect(mapStateToProps)(Pagination as any);
