import { LogLevels, logMessage, LogTypes } from "@ic-anywhere/ic-utils";
import { Field, useFormikContext } from "formik";
import { filter, isEmpty, map, trim, debounce } from "lodash-es";
import * as React from "react";
import { getCheckDns } from "../../api/google/google.api";
import FormGroup from "../../common/components/FormGroup/FormGroup";
import { ContactCreatorFormModel, CreateLinkMode, EmitOnChangeMode, PepSpoType } from "../../common/typings";
import { AccountWidget } from "../../common/typings/accounts.typings";
import SingleAccountPicker from "../../ic-account-single-picker/AccountSingleSelectPicker";
import {
  ContactCreatorContext,
  ContactCreatorContextProviderProps,
} from "../contextProvider/ContactCreatorContextProvider";
import { defaultEmailPattern, pepSpoTypes, titleLabelsValues } from "./contants";
import EmptySelectOption from "../../common/components/Form/EmptySelectOption";
import { FormattedMessage, useIntl } from "react-intl";
import { DEBOUNCE_SEARCH_TIME } from "../../common/constants";
import JobTypeField from "./JobTypeField";
import useCivilities from "../../common/hooks/useCivilities";
import { Switch } from "@sgbs-ui/core";

interface Props {
  accountId?: string;
  isLight?: boolean;
  accountEmitMode?: EmitOnChangeMode;
  createLinkMode?: CreateLinkMode;
  autofocus?: boolean;
  personInfoReadOnly?: boolean;
  onCreateAccount?: (accountName?: string) => void;
}

export const mapAccountAddressToContactAddress = (account: AccountWidget | null) => {
  const accountAddress = account ? account.mainAddress : undefined;

  return {
    street1: accountAddress?.street1 ?? "",
    zipCode: accountAddress?.zipCode ?? "",
    city: accountAddress?.city ?? "",
    country: !isEmpty(accountAddress)
      ? {
          iso2: "",
          iso3: accountAddress?.countryIso3 ?? "",
          name: accountAddress?.country ?? "",
          areaCode: "",
        }
      : account
      ? {
          iso3: account.countryIso3 ?? "FRA",
          name: account.countryName ?? "France",
        }
      : {
          iso3: "FRA",
          name: "France",
        },
  };
};

const PersonalInformationForm: React.FC<Props> = ({
  accountId,
  isLight = false,
  accountEmitMode = "user",
  createLinkMode,
  autofocus,
  personInfoReadOnly,
  onCreateAccount,
}) => {
  const { context, actions } = React.useContext(ContactCreatorContext) as ContactCreatorContextProviderProps;
  const { person, emailCheckError, emailCheckSuccess, contactSuggestion } = context;
  const { touched, errors, values, setValues, setFieldValue, isSubmitting, setFieldTouched } = useFormikContext<
    ContactCreatorFormModel
  >();
  const { mainEmail, employeeOfAccountId, pepSpoType, accountSuggestionIds } = values;
  const [isAccountTouched, setIsAccountTouched] = React.useState<boolean>(false);

  const [isDnsInError, setIsDnsInError] = React.useState<boolean>(false);
  const { formatMessage } = useIntl();

  const civilityLabelsValues = useCivilities();

  const groupLabel = formatMessage({ id: "contact.civility.group", defaultMessage: "Group" });
  const groupValue = "Group";

  React.useEffect(() => {
    if (isSubmitting) {
      setIsAccountTouched(true);
      if (isDnsInError) {
        logMessage({
          name: "incorrect_email",
          feature: "ic-contact-creator",
          type: LogTypes.technical,
          level: LogLevels.Info,
          description: `Incorrect domain and next button clicked : ${mainEmail?.value}`,
        });
      }
    }
  }, [isSubmitting]);

  const handleOnAccountChange = (account: AccountWidget | null) => {
    if (account) {
      setIsAccountTouched(true);
    }

    const mainAddress = mapAccountAddressToContactAddress(account);

    setValues({
      ...values,
      employeeOfAccountId: account?.id ?? "",
      employeeOfAccountIso3: account?.countryIso3,
      addresses: !isEmpty(values.addresses) ? values.addresses : mainAddress ? [mainAddress] : [],
    });

    actions.setMainAddress(mainAddress);
  };

  React.useEffect(() => {
    if (isLight && !emailCheckError && emailCheckSuccess) {
      actions.createContact(values);
    }
  }, [isLight, emailCheckError, emailCheckSuccess, values]);

  React.useEffect(() => {
    if (person && setFieldValue) {
      setFieldValue("person.id", person.id);
      setFieldValue("firstName", person.givenName);
      setFieldValue("lastName", person.name);
    }
  }, [person, setFieldValue]);

  React.useEffect(() => {
    checkIfDnsExistDebounce(mainEmail.value);
  }, [mainEmail]);

  React.useEffect(() => {
    if (isLight && mainEmail) {
      actions.getContactSuggestions(mainEmail.value);
    }
  }, [isLight, mainEmail]);

  React.useEffect(() => {
    if (isLight && contactSuggestion && setFieldValue) {
      const { firstName, lastName, accountIds } = contactSuggestion;
      setFieldValue("accountSuggestionIds", accountIds);
      setFieldValue("firstName", firstName);
      setFieldValue("lastName", lastName);
    }
  }, [isLight, contactSuggestion, setFieldValue]);

  React.useEffect(() => {
    setFieldValue("employeeOfAccountId", accountId);
  }, [accountId]);

  const handleOnClearSuggestions = () => {
    setFieldValue("accountSuggestionIds", []);
  };

  const handleOnRemoveSuggestion = (item: AccountWidget) => {
    setFieldValue(
      "accountSuggestionIds",
      filter(values?.accountSuggestionIds, id => id !== item.id)
    );
  };

  const checkIfDnsExist = async (email: string) => {
    if (!!email && defaultEmailPattern.test(email)) {
      const result = await getCheckDns(email.split("@")?.[1]);
      if (result?.Status !== 0) {
        logMessage({
          name: "incorrect_email",
          feature: "ic-contact-creator",
          type: LogTypes.technical,
          level: LogLevels.Info,
          description: `Incorrect domain : ${email}`,
        });
      }
      setIsDnsInError(result?.Status !== 0);
    }
  };

  const checkIfDnsExistDebounce = React.useCallback(debounce(checkIfDnsExist, DEBOUNCE_SEARCH_TIME), []);

  return (
    <>
      {!isLight && (
        <h4>
          <FormattedMessage id="contact.personalInformation" defaultMessage={"Personal information"} />
        </h4>
      )}
      <FormGroup
        title={formatMessage({
          id: "global.account",
          defaultMessage: "Account",
        })}
        touched={isAccountTouched}
        errors={errors.employeeOfAccountId}
      >
        <SingleAccountPicker
          legalOnly={true}
          autofocus={autofocus}
          placeholder={formatMessage({
            id: "global.picker.account.placeholder",
            defaultMessage: "Search a name, BDR ID or RCT ID",
          })}
          selectedId={employeeOfAccountId}
          suggestionIds={accountSuggestionIds}
          emitMode={accountEmitMode}
          createLinkMode={createLinkMode}
          onChange={handleOnAccountChange}
          onCreateLinkClicked={onCreateAccount}
          inError={isAccountTouched && !!errors.employeeOfAccountId}
          onClearSuggestions={handleOnClearSuggestions}
          onRemoveSuggestion={handleOnRemoveSuggestion}
          displayOption={"city"}
        />
      </FormGroup>

      <div className="mb-2">
        <Switch
          id="civility-group"
          text={groupLabel}
          checked={values.civility === groupValue}
          onChange={value => setFieldValue("civility", value ? groupValue : undefined)}
          disabled={personInfoReadOnly}
          className="text-secondary font-weight-bold"
        />
      </div>

      <div className="row mb-2">
        {values.civility !== "Group" && (
          <FormGroup
            className={`${isLight ? "col-12" : "col-6"} mb-2`}
            title={formatMessage({
              id: "contact.civility",
              defaultMessage: "Civility",
            })}
            touched={touched.civility}
            errors={errors.civility}
          >
            <Field
              as="select"
              name="civility"
              className={`form-control ${touched.civility && errors.civility && "is-invalid"}`}
              disabled={personInfoReadOnly}
            >
              <EmptySelectOption />
              {map(civilityLabelsValues, ({ label, value }) => (
                <option key={label} value={value}>
                  {label}
                </option>
              ))}
            </Field>
          </FormGroup>
        )}

        {values.civility !== "Group" && !isLight && (
          <FormGroup
            className="col-6 mb-2"
            title={`${formatMessage({
              id: "global.title",
              defaultMessage: "Title",
            })} (${formatMessage({
              id: "global.optional",
              defaultMessage: "optional",
            })})`}
          >
            <Field as="select" name="title" className="form-control">
              <EmptySelectOption />
              {map(titleLabelsValues, ({ label, value }) => (
                <option key={label} value={value}>
                  {label}
                </option>
              ))}
            </Field>
          </FormGroup>
        )}
      </div>

      {values.civility !== "Group" && (
        <div className="row mb-2">
          <FormGroup
            className="col-6 mb-2"
            title={formatMessage({
              id: "contact.firstName",
              defaultMessage: "First name",
            })}
            touched={touched.firstName}
            errors={errors.firstName}
          >
            <Field
              name="firstName"
              className={`form-control ${touched.firstName && errors.firstName && "is-invalid"}`}
              disabled={personInfoReadOnly}
            />
          </FormGroup>
          <FormGroup
            className="col-6 mb-2"
            title={formatMessage({
              id: "contact.lastName",
              defaultMessage: "Last name",
            })}
            touched={touched.lastName}
            errors={errors.lastName}
          >
            <Field
              name="lastName"
              className={`form-control ${touched.lastName && errors.lastName && "is-invalid"}`}
              disabled={personInfoReadOnly}
            />
          </FormGroup>
        </div>
      )}

      {values.civility === "Group" && (
        <div className="row mb-2">
          <FormGroup
            className="col-12 mb-2"
            title={formatMessage({
              id: "global.name",
              defaultMessage: "Name",
            })}
            touched={touched.groupName}
            errors={errors.groupName}
          >
            <Field
              name="groupName"
              className={`form-control ${touched.groupName && errors.groupName && "is-invalid"}`}
            />
          </FormGroup>
        </div>
      )}

      <FormGroup
        title={formatMessage({
          id: "contact.modal.personCreator.professionalEmail",
          defaultMessage: "Professional email",
        })}
        touched={isDnsInError || touched.mainEmail?.value}
        errors={
          !!errors.mainEmail?.value
            ? errors.mainEmail?.value
            : isDnsInError
            ? `email domain ${values?.mainEmail?.value?.split("@")?.[1]} unknown`
            : ""
        }
        isWarning={!errors.mainEmail?.value && isDnsInError}
      >
        <Field
          name="mainEmail.value"
          className={`form-control ${
            touched.mainEmail && errors.mainEmail ? "is-invalid" : isDnsInError && "is-warning"
          } `}
          onBlur={event => {
            setFieldValue("mainEmail.value", trim(event?.currentTarget?.value));
            setFieldValue("mainEmail.personalEmailJustificationId", undefined);
            setFieldTouched("mainEmail.value", true);
          }}
        />
      </FormGroup>

      <FormGroup
        title={formatMessage({
          id: "global.jobTitle",
          defaultMessage: "Job title",
        })}
        touched={touched.jobTitle}
        errors={errors.jobTitle}
      >
        <Field name="jobTitle" className={`form-control ${touched.jobTitle && errors.jobTitle && "is-invalid"}`} />
      </FormGroup>
      <FormGroup
        title={formatMessage({
          id: "contact.jobType",
          defaultMessage: "Job type",
        })}
        touched={touched.jobType}
        errors={errors.jobType}
      >
        <JobTypeField />
      </FormGroup>

      {!isLight && (
        <>
          <FormGroup
            title={formatMessage({
              id: "contact.pep.title",
              defaultMessage: "Politically exposed person (PEP) / Relatives and Close Associates (RCA)",
            })}
          >
            <div
              className="btn-group btn-group-toggle"
              data-toggle="buttons"
              role="group"
              aria-label="toggle buttons single select"
            >
              {map(pepSpoTypes, ({ label }) => (
                <label key={label} className={`btn ${pepSpoType === label ? "btn-primary" : "btn-default"}`}>
                  <Field
                    type="radio"
                    name="pepSpoType"
                    onChange={() => setValues({ ...values, pepSpoType: label as PepSpoType })}
                  />

                  {formatMessage({
                    id: `global.${label.toLocaleLowerCase()}`,
                    defaultMessage: label,
                  })}
                </label>
              ))}
            </div>
          </FormGroup>
        </>
      )}
    </>
  );
};

export default PersonalInformationForm;
