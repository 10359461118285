import { ICounterpartSignatory } from "../domain/ICounterpartSignatory";
import { IProofOfConsentInformation } from "../domain/IProofOfConsentInformation";
import { ITransaction, ITransactionStatus } from "../domain/ITransaction";

const COMPLETED = Object.keys(ITransactionStatus).indexOf(ITransactionStatus.COMPLETED);
const COMPLETED_BY_ADMIN = Object.keys(ITransactionStatus).indexOf(ITransactionStatus.COMPLETED_BY_ADMIN);
const COMPLETED_PROVIDER_STATUS_NOT_FOUND = Object.keys(ITransactionStatus).indexOf(ITransactionStatus.COMPLETED_PROVIDER_STATUS_NOT_FOUND);

export function isTransactionSigned(transaction: ITransaction): boolean {
  if (typeof transaction.status === 'number') {
    return [
      COMPLETED, COMPLETED_BY_ADMIN,
      COMPLETED_PROVIDER_STATUS_NOT_FOUND
    ].includes(transaction.status);
  } else {
    return [
      ITransactionStatus.COMPLETED.toString(),
      ITransactionStatus.COMPLETED_BY_ADMIN.toString(),
      ITransactionStatus.COMPLETED_PROVIDER_STATUS_NOT_FOUND.toString()
    ].includes(transaction.status.toUpperCase())
  }
}

export function isTransactionExpired(transaction: ITransaction): boolean {
  return transaction.deadline != undefined
    && new Date(transaction.deadline).getTime() <= new Date().getTime();
}

export function getCurrentSignatoryPhoneNumber(signatory: ICounterpartSignatory, initialInformation: IProofOfConsentInformation): string {
  const currentEmail = signatory.identifier;
  if(currentEmail) {
    return initialInformation.potentialSignatoriesPhones[currentEmail] || "no phone";
  }
  return "no phone"
}

export function isConsentCheck(
  step: number,
  consent: string,
  checkboxLabel: string,
  isBasedOnSignatureWorkflow: boolean,
  existingConsents: Map<number, string[]>
): boolean {
  const consents = existingConsents.get(step);
  if(consents) {
    if (isBasedOnSignatureWorkflow) {
      const correspondingConsent = consents.find(consent => consent === checkboxLabel);
      return (correspondingConsent && correspondingConsent.length > 0) as boolean;
    } else {
      return consents[0] != undefined;
    }
  }
  return isBasedOnSignatureWorkflow
    ? false
    : consent != undefined;
}

export function isTransactionValid(
  signable: boolean, 
  transaction: ITransaction, 
  isValidSignatory: boolean, 
  isPocSigned: boolean, 
  isTransactionSigned: boolean
): boolean {
  return signable
    && (transaction && (!transaction.deadline || new Date(transaction.deadline).getTime() > new Date().getTime()))
    && (isValidSignatory || !isPocSigned)
    && !isTransactionSigned;
}