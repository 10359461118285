import {
  IWidgetConfigurationProps,
  withWidgetConfiguration,
} from "@sgwt-widget/core";
import { Component, h } from "preact";
import { MarkupText } from "preact-i18n";
import { connect } from "preact-redux";
import { Action } from "redux";
import { IChannel } from "../../../common/domain/IChannel";
import { IOtp, OtpMode } from "../../../common/domain/IOtp";
import { registerCertificate } from "../../../common/services/CertificateService";
import { openChannel } from "../../../common/services/ChannelService";
import { requestOtp, submitOtp } from "../../../common/services/OtpService";
import { setChannel, setIsCertificatePending, setIsOTPValid, setOtp } from "../../actions/ISignatureAction";
import { Spinner } from "../../components/spinner/Spinner";
import { ApplicationState } from "../../states/ApplicationState";
import { css } from "../../styles";
import { ISignatureWorkflow, ISignatureWorkflowStep } from "../../../common/domain/ISignatureWorkflow";
import { getOtpIndex, getSignatureWorkflowByLanguage } from "../../../common/services/SignatureWorkflow";
import { Alert } from "../../components/alert/Alert";
import { ITransaction } from "../../../common/domain/ITransaction";
import "./Otp.scss";

interface IOtpState {
  otp: IOtp;
  channel: IChannel;
  processing: boolean;
  isCertificatePending: boolean;
  otpSendStatus: { send: boolean, error: boolean };
  otpValidationStatus: { validated: boolean, error: boolean };
  
}

export interface IOtpProps {
  step: number;
  consent: string;
  otpMode: OtpMode;
  language: string;
  isOTPValid: boolean;
  transactionId: number;
  transaction: ITransaction;
  isPublicSignatory: boolean;
  documentsValidation: boolean;
  consents: Map<number, string[]>;
  signatureWorkflow: ISignatureWorkflow;
  setOtp: (otp: IOtp) => void;
  setChannel: (channel: IChannel) => void;
  setIsOTPValid: (isOTPValid: boolean) => void;
  setIsCertificatePending: (isCertificatePending: boolean) => void;
}

const Otp = withWidgetConfiguration(
  class extends Component<IOtpProps & IWidgetConfigurationProps,
    IOtpState> {
    private otpInput: HTMLInputElement;
    private otpStepIndex = !!this.props.signatureWorkflow && getOtpIndex(this.props.signatureWorkflow.languages);
    private otpSendCounter = -1;

    public async componentDidMount() {
      this.setState({
        otpSendStatus: { send: false, error: false },
        otpValidationStatus: { validated: false, error: false },
        isCertificatePending: false
      });
      this.resetOTPInputField();
      this.sendOTP(this.props);
      this.props.setIsOTPValid(false);
    }

    public async componentDidUpdate() {
      this.otpInput.focus();
      this.otpInput.addEventListener("input", this.trimOtpValue);
    }

    private resetOTPInputField() {
      this.otpInput.value = "";
    }

    private isOTPStep(): boolean {
      return this.otpStepIndex === this.props.step;
    }

    private isConsentValid = (props: IOtpProps): boolean => {
      if (props.signatureWorkflow) {
        if (!props.consents) {
          return false;
        }

        const signatureWorkflowSteps: ISignatureWorkflowStep[] = getSignatureWorkflowByLanguage(
          props.language,
          props.signatureWorkflow.languages
        );
        signatureWorkflowSteps.forEach((signatureWorkflowStep, step) => {
          if (signatureWorkflowStep.checkbox && !!props.consents.get(step)) {
            return false;
          }
        });

        return true;
      }

      return !!props.consent && props.consent !== "";
    };

    private getMergedConsents = (): string => {
      if (this.props.signatureWorkflow) {
        const res: string[] = [];
        this.props.consents.forEach(value => {
          res.push(value.join("\n"));
        });

        return res.join("\n\n");
      }
      return this.props.consent;
    };

    private readonly trimOtpValue = () => {
      this.otpInput.value = this.otpInput.value.trim();
    }

    private async sendOTP(props: IOtpProps) {
      this.setState({
        otpSendStatus: { send: false, error: false },
        otpValidationStatus: { validated: false, error: false },
      });
      if (
        this.isOTPStep() &&
        this.isConsentValid(props) &&
        props.documentsValidation
      ) {
        openChannel(
          this.props.widgetConfiguration,
          this.props.transactionId,
          this.props.otpMode,
        )
          .then((channel: IChannel) => {
            this.setState({ channel });
            this.props.setChannel(channel);
            return requestOtp(
              this.props.widgetConfiguration,
              this.props.transactionId,
              channel,
              this.props.otpMode,
              props.language,
            );
          })
          .then((otp: IOtp) => {
            this.setState({
              otp,
              otpSendStatus: { send: true, error: false }
            });
            this.props.setOtp(otp);
          }).catch((error) => {
            console.error(error);
            this.setState({
              otpSendStatus: { send: false, error: true }
            })
          });
        this.setState({ processing: false });
        this.otpSendCounter++;
      }
    }

    private submit(e: Event) {
      this.props.setChannel(this.state.channel);
      e.preventDefault();
      this.setState({
        processing: true,
        otp: {
          channel: this.state.otp.channel,
          otp: this.otpInput.value,
        },
        otpSendStatus: { send: false, error: false },
        otpValidationStatus: { validated: false, error: false },
      });
      this.props.setOtp(this.state.otp);
      submitOtp(
        this.props.widgetConfiguration,
        this.props.transactionId,
        this.state.otp,
        this.state.channel,
      )
        .then(() => {
          this.setState({
            isCertificatePending: true
          })
          this.props.setIsCertificatePending(this.state.isCertificatePending);
          registerCertificate(
            this.props.widgetConfiguration,
            this.props.transactionId,
            this.state.channel,
            this.getMergedConsents(),
          ).then(() => {
            this.setState({
              isCertificatePending: false
            })
            this.props.setIsCertificatePending(this.state.isCertificatePending);
          });
          this.setState({
            otpValidationStatus: { validated: true, error: false },
          });
          this.props.setIsOTPValid(true);
        }).catch((error) => {
          console.error(error);
          this.setState({
            otpValidationStatus: { validated: false, error: true },
          });
          this.props.setIsOTPValid(false);
        });
      this.setState({
        processing: false,
      })
    }

    private getAlertMessage(): { isSuccess: boolean, messageId: string } {
      const defaultValue = {
        isSuccess: false,
        messageId: this.props.language + ".otp-modal.otp error",
      };
      if (!!this.state.otpValidationStatus && !!this.state.otpSendStatus) {
        if (this.state.otpValidationStatus.validated && !this.state.otpValidationStatus.error) {
          return {
            isSuccess: true,
            messageId: this.props.language + ".otp-modal.otp is valid",
          }
        } else if (!this.state.otpValidationStatus.validated && this.state.otpValidationStatus.error) {
          return {
            isSuccess: false,
            messageId: this.props.language + ".otp-modal.otp is wrong",
          }
        } else if (this.state.otpSendStatus.send && !this.state.otpSendStatus.error) {
          return {
            isSuccess: true,
            messageId: this.props.language + ".otp-modal.otp resend success",
          }
        } else if (!this.state.otpSendStatus.send && this.state.otpSendStatus.error) {
          return {
            isSuccess: false,
            messageId: this.props.language + ".otp-modal.otp resend failure",
          }
        } else {
          return defaultValue;
        }
      } else {
        return defaultValue;
      }
    }

    private canDisplayAlert(): boolean {
      return (
        ((this.otpSendCounter > 0 && this.state.otpSendStatus.send) || (this.state.otpSendStatus && this.state.otpSendStatus.error))
        || (this.state.otpValidationStatus && (this.state.otpValidationStatus.validated || this.state.otpValidationStatus.error))
      )
        && this.getAlertMessage().messageId != undefined;
    }

    public render(): JSX.Element {
      return (
        <section className={css("row")}>
          <section className={css("col-xs-2 col-sm-3 col-md-3 col-lg-4 col-xl-4")}></section>
          <section className={css("col-xs-8 col-sm-6 col-md-6 col-lg-4 col-xl-4")}>
            <article className={css("mt-2 mb-2 pt-3 pb-3")} style={"height: 134px"}>
              <div className={css("p-1 overflow-hidden")}>
                <article className={css(this.state.processing ? "" : "d-none")}>
                  <Spinner loading={this.state.processing} />
                </article>
                <article className={css(this.state.processing ? "d-none" : "")}>
                  <div className={"required"}>
                    <MarkupText className={"required"} id={this.props.language + ".otp.otp-label"}>
                      Enter the code here
                    </MarkupText>
                  </div>
                  <form onSubmit={(e: Event) => this.submit(e)}>
                    <div className={css("form-group")}>
                      <input
                        type="text"
                        className={css(
                          "form-control form-control-md",
                          this.state.processing ? "disabled" : ""
                        )}
                        ref={(input: HTMLInputElement) =>
                          (this.otpInput = input)
                        }
                        disabled={this.state.processing}
                      />
                    </div>
                  </form>
                  <Alert canDisplayAlert={this.canDisplayAlert()} alertMessage={this.getAlertMessage()} />
                  <section className={css("row")}>
                    <section className={css("col d-inline-flex")}>
                      <button className={css("btn btn-md btn-outline-primary")}
                        onClick={() => this.sendOTP(this.props)}
                        disabled={this.state.processing || this.props.isOTPValid}
                      >
                        <MarkupText id={this.props.language + ".otp.otp-resend"}>
                          Resend OTP
                        </MarkupText>
                      </button>
                      <button
                        type="button"
                        className={css("btn btn-md btn-primary ml-auto", this.state.processing ? "disabled" : "")}
                        onClick={(e: Event) => this.submit(e)}
                        disabled={this.state.processing || this.props.isOTPValid}
                      >
                        <MarkupText id={this.props.language + ".otp.otp-validate"}>
                          Validate OTP
                        </MarkupText>
                      </button>
                    </section>
                  </section>
                </article>
              </div>
            </article>
          </section>
          <section className={css("col-xs-2 col-sm-3 col-md-3 col-lg-4 col-xl-4")}></section>
        </section>
      );
    }
  }
);

function mapStateToProps(state: ApplicationState) {
  return {
    step: state.signatureState.step,
    consent: state.signatureState.consent,
    otpMode: state.signatureState.otpMode,
    language: state.languageState.language,
    isOTPValid: state.signatureState.isOTPValid,
    transactionId: state.transactionIdState.transactionId,
    transaction: state.transactionState.transaction,
    isPublicSignatory: state.signatureState.isPublicSignatory,
    documentsValidation: state.signatureState.documentsValidation,
    consents: state.signatureState.consents,
    signatureWorkflow: state.signatureState.signatureWorkflow,
  };
}

const mapDispatchToProps = (dispatch: (action: Action) => void) => ({
  setOtp: (otp: IOtp) => dispatch(setOtp(otp)),
  setChannel: (channel: IChannel) => dispatch(setChannel(channel)),
  setIsOTPValid: (isOTPValid: boolean) => dispatch(setIsOTPValid(isOTPValid)),
  setIsCertificatePending: (isCertificatePending: boolean) => dispatch(setIsCertificatePending(isCertificatePending))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Otp as any);