import { Component, h } from "preact";
import { css } from "../../styles";
import { FULL_DOCUMENT_READ } from "../../../common/helpers/documentHelper";


interface IProgressBarProps {
  readCompletion: number;
}

export function getProgressBarCss(readCompletion: number, className: string): string {
  return readCompletion === FULL_DOCUMENT_READ ? `${className}-success`
    : (readCompletion > 80 && readCompletion < FULL_DOCUMENT_READ
      ? `${className}-warning`
      : `${className}-danger`
    )
}

export class ProgressBarComponent extends Component<IProgressBarProps> {
  public render(): JSX.Element {
    return (
      <div className={css("progress progress-sm")}>
        <div
          className={`${css("progress-bar")} ${getProgressBarCss(this.props.readCompletion, "bg")}`}
          role={"progressbar"}
          style={`width: ${this.props.readCompletion}%`}
          aria-valuenow={`${this.props.readCompletion}`}
          aria-valuemin={"0"}
          aria-valuemax={`${FULL_DOCUMENT_READ}`}
        />
      </div>
    )
  }
}
