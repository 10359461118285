import * as React from "react";
import { useField } from "formik";
import FormikFormGroup from "../../../common/components/Form/FormikFormGroup";
import { useIntl } from "react-intl";
import { BankAccount, BankAccountMutliPicker } from "@ic-anywhere/ic-components";
import { ThirdId } from "@ic-anywhere/ic-dal";
import useContactDbRepository from "../../../api/useContactDbRepository";

interface Props {
  thirdId: ThirdId | undefined;
  show: boolean;
}

const BankAccountField: React.FC<Props> = ({ thirdId, show = false }: Props) => {
  const [, { value }, { setValue, setTouched }] = useField<BankAccount | undefined>("bankAccounts");
  const { formatMessage } = useIntl();
  const repo = useContactDbRepository();

  const handleOnChange = (from: BankAccount | undefined): void => {
    setValue(from);
    setTouched(true);
  };
  return show ? (
    <FormikFormGroup
      label={formatMessage({
        id: "global.bankAccounts",
        defaultMessage: "Bank account(s)",
      })}
      fieldName="bankAccounts"
    >
      <BankAccountMutliPicker
        onChange={handleOnChange}
        repo={repo}
        bankAccount={value}
        thirdId={thirdId ?? {}}
        placeholder={formatMessage({
          id: "global.picker.bankAccount.placeholder",
          defaultMessage: "Search bank account",
        })}
        inErrorPlaceholder={formatMessage({
          id: "global.picker.bankAccount.placeholder.inError",
          defaultMessage: "Only LE or EC level can have a bank account",
        })}
        formatMessage={formatMessage}
      />
    </FormikFormGroup>
  ) : (
    <></>
  );
};

export default BankAccountField;
