import { IWidgetConfigurationProps, withWidgetConfiguration } from "@sgwt-widget/core";
import { Component, h } from "preact";
import { ApplicationState } from "../../states/ApplicationState";
import { Action } from "redux";
import { connect } from "preact-redux";
import { allDocumentsRead } from "../../actions/ISignatureAction";
import { ISignatureWorkflow } from "../../../common/domain/ISignatureWorkflow";

export interface IWorkflowStepperProps {
  language: string;
  signatureWorkflow: ISignatureWorkflow;
  step: number;
  allDocumentsAreRead: (areRead: boolean) => void;
}

const WorkflowStepper = withWidgetConfiguration(
  class extends Component<IWorkflowStepperProps & IWidgetConfigurationProps> {

    private getCurrentStepIndex(): number {
      if (
        this.props.signatureWorkflow &&
        this.props.signatureWorkflow.languages
      ) {
        return this.props.step;
      }
      return 0;
    }

    private stepperWithNumbers(): JSX.Element {
      if (
        this.props.signatureWorkflow &&
        this.props.signatureWorkflow.languages
      ) {
        const numberOfStep = this.props.signatureWorkflow.languages.fr.length
        return <div>
          {this.getCurrentStepIndex() + 1}/{numberOfStep}
        </div>
      }
      return <div></div>
    }

    private stepperWithDots(): JSX.Element[] {
      const steps: JSX.Element[] = [];
      if (
        this.props.signatureWorkflow &&
        this.props.signatureWorkflow.languages
      ) {
        const numberOfStep = this.props.signatureWorkflow.languages.fr.length
        for(let i = 0; i < numberOfStep; i++) {
          steps.push(
            <em class="material-icons" style={"font-size: 12px"}>
              { i === this.getCurrentStepIndex()
                ? "radio_button_checked"
                : "radio_button_unchecked"
              }
            </em>
          );
        }
      }
      return steps;
    }

    public render(): JSX.Element {
      return (
        <div>
          <article className={`not-mobile-article`}>
            {this.stepperWithDots()}
          </article>
          <article className={`mobile-article`}>
            {this.stepperWithNumbers()}
          </article>
        </div>
      );
    }
  });

function mapStateToProps(state: ApplicationState) {
  return {
    language: state.languageState.language,
    signatureWorkflow: state.signatureState.signatureWorkflow,
    step: state.signatureState.step,
  }
};

const mapDispatchToProps = (dispatch: (action: Action) => void) => ({
  allDocumentsAreRead: (areRead: boolean) => dispatch(allDocumentsRead(areRead)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkflowStepper as any);
