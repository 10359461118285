import "./SignatoriesBlock.scss";
import {Component, h} from "preact";
import {MarkupText} from "preact-i18n";
import {connect} from "preact-redux";

import {ISignatureCounterpart} from "../../../common/domain/ISignatureCounterpart";
import {ITransaction} from "../../../common/domain/ITransaction";
import {CounterpartsComponent} from "./counterpart-component/CounterpartsComponent";
import {ApplicationState} from "../../states/ApplicationState";
import {css} from "../../styles";
import {IUser} from "../../../common/domain/User";
import {IWidgetConfigurationProps, withWidgetConfiguration} from "@sgwt-widget/core";

interface IState {
  counterparts: ISignatureCounterpart[];
  language: string;
  collapsed: boolean;
  esignServiceUrl: string;
}

export interface ISignatoriesBlockProps {
  language: string;
  transaction: ITransaction;
  currentUser: IUser;
}

const SignatoriesBlock = withWidgetConfiguration(
  class extends Component<ISignatoriesBlockProps & IWidgetConfigurationProps, IState> {
    public async componentWillMount() {
      this.setState({
        collapsed: false,
        esignServiceUrl: (await this.props.widgetConfiguration.myConfiguration<ISignatureConfig>()).esignServiceUrl,
      });
    }

    public async componentDidMount() {
      if (this.props.transaction) {
        this.setState({counterparts: this.props.transaction.signatureCounterparts});
      }
      if (this.props.language) {
        this.setState({language: this.props.language});
      }
    }

    public async componentWillReceiveProps(nextProps: ISignatoriesBlockProps) {
      if (nextProps.transaction) {
        this.setState({counterparts: nextProps.transaction.signatureCounterparts});
      }
      if (nextProps.language) {
        this.setState({language: nextProps.language});
      }
    }

    public render(): JSX.Element {
      return (
        <section id="accordion">
          <section className={css("card mb-2 bg-lvl2")}>
            <section className={css("pl-0 card-header")} id="signatoriesSection">
              <h5 className={css("mb-0")}>
                <button
                  className={`${css("btn btn-lg btn-flat-primary justify-content-start pl-0 ml-2 d-block")} pointer`}
                  
                  data-toggle={css("collapse")}
                  data-target="#signatories"
                  aria-expanded="true"
                  aria-controls="signatories"
                  onClick={() =>
                    this.setState({collapsed: !this.state.collapsed})
                  }
                >
                  <i
                    className={`material-icons icon mr-2 ${
                      this.state.collapsed ? "collapsed" : ""
                      }`}
                  >
                    {this.state.collapsed ? 'keyboard_arrow_right' : 'expand_more'}
                  </i>
                  <span className={css('align-top')} style={{fontSize: 16, display: "contents"}}>
                  <MarkupText id={this.state.language + ".signatories.title"} >
                    Signatory overview
                  </MarkupText>
                </span>
                </button>
                <section
                  id="signatories"
                  className={css("collapse", this.state.collapsed ? "" : "show")}
                  aria-labelledby="signatoriesSection"
                  data-parent="#accordion"
                >
                  <section className={"signatories-card"} style={"max-width: 550px;"}>
                    <CounterpartsComponent
                      counterparts={this.state.counterparts}
                      language={this.state.language}
                      currentUser={this.props.currentUser}
                      transactionId={this.props.transaction.id}
                      esignBaseUrl={this.state.esignServiceUrl}
                    />
                  </section>
                </section>
              </h5>
            </section>
          </section>
        </section>
      );
    }
  });

function mapStateToProps(state: ApplicationState) {
  return {
    currentUser: state.userState.user,
    language: state.languageState.language,
    transaction: state.transactionState.transaction,
  };
}

export default connect(mapStateToProps)(SignatoriesBlock as any);
