import { FetchFn } from "@sg-widgets/platform-api/src/index";
import { createRepository } from "../api";
import {
  College,
  DelegationDTO,
  FetchCollegesParam,
  FetchCollegesResponse,
  FetchDelegationParam,
  MandateDTO,
  MandateSearchParams,
  RoleItemDTO,
  FetchPowerResponse,
  CreateDocumentDto,
  BankAccountDTO,
} from "./mandate.typings";
import { CONTACT_DB_HEADERS } from "../api.constants";
import { Mandate, MandatePayload, SavedMandate } from "../../common/typings/mandate.typing";
import { mapCollegesDTOToColleges, mapMandateList, mapToMandateSaveDTO, mapToSavedMandate } from "./mandate.mapper";
import { ContactId } from "../../common/typings/contacts.typings";
import { ThirdId } from "../maestro/maestro.typings";
import { isStringEmpty } from "../../utils/strings/stringUtils";
import { stringifyLists } from "../../utils/strings/stringify";
import { PowerDefinition } from "./power.typings";

const url = "/1.0/mandates";

export const fetchMandates = (
  fetch: FetchFn,
  contactId: ContactId,
  thirdId: ThirdId,
  accountId: string,
  { businessFunctionId, roleId, delegationId, contactEmail, contactPerimeter, roleItemIds }: MandateSearchParams
): Promise<Mandate[]> => {
  return createRepository(fetch, CONTACT_DB_HEADERS)
    .get<{ mandates: MandateDTO[] }>(
      url,
      stringifyLists({
        pageSize: 500,
        bdrId: isStringEmpty(thirdId?.bdrId) ? undefined : thirdId?.bdrId,
        level: isStringEmpty(thirdId?.level) ? undefined : thirdId?.level,
        accountId: isStringEmpty(accountId) ? undefined : accountId,
        mandatedContactId: isStringEmpty(contactId?.id) ? undefined : contactId?.id,
        mandatedContactType: isStringEmpty(contactId?.type) ? undefined : contactId?.type,
        businessFunctionId: isStringEmpty(businessFunctionId) ? undefined : businessFunctionId,
        roleId: isStringEmpty(roleId) ? undefined : roleId,
        delegationId: isStringEmpty(delegationId) ? undefined : delegationId,
        roleItemIds: isStringEmpty(roleItemIds) ? undefined : roleItemIds,
        mode: "all",
        activeOnly: true,
        fields: ["jobtitle", "delegation", "accessRights"],
        contactEmail: !contactId?.id ? contactEmail : undefined,
        contactPerimeter,
      })
    )
    .then(response => mapMandateList(response.mandates.filter(m => m.accessRights?.grantedRights !== "NoAccess")));
};

export const fetchFunctionRoles = (
  fetch: FetchFn,
  language = "en",
  onlyAssignableByMe = true
): Promise<RoleItemDTO[]> => {
  let roleItems: RoleItemDTO[] = [];
  let page = 0;
  const request = (url: string, params?: Record<string, any> | undefined): Promise<RoleItemDTO[]> =>
    createRepository(fetch, { ...CONTACT_DB_HEADERS, "accept-language": language })
      .get<{ roleItems: RoleItemDTO[]; nextPageUri: string }>(url, params)
      .then(async result => {
        roleItems = roleItems.concat(result.roleItems);
        if (!!result.nextPageUri) {
          page = page + 1;
          await request(url, {
            page,
            pageSize: 500,
            onlyAssignableByMe,
          });
        }
        return roleItems;
      });
  return request(`${url}/roleItems`, {
    page,
    pageSize: 500,
    onlyAssignableByMe,
  });
};

export const saveMandate = (
  fetch: FetchFn,
  mandateForm: MandatePayload,
  contact: ContactId,
  bankAccount?: BankAccountDTO
): Promise<SavedMandate> =>
  createRepository(fetch, CONTACT_DB_HEADERS)
    .post<{ id: string }>(url, mapToMandateSaveDTO(mandateForm, contact, bankAccount))
    .then(response => {
      return mapToSavedMandate(response.id, mandateForm, contact);
    });
export const addDocumentToMandate = (fetch: FetchFn, payload: CreateDocumentDto): Promise<string> => {
  return createRepository(fetch, CONTACT_DB_HEADERS)
    .upload<{ documentId: string }>(`${url}/${payload.mandateId}/documents/${payload.type}`, [payload.file])
    .then(response => response.documentId);
};
export const fetchPowerDefinitions = (fetch: FetchFn): Promise<PowerDefinition[]> =>
  createRepository(fetch, CONTACT_DB_HEADERS)
    .get<FetchPowerResponse>(`/1.0/mandate-powers`)
    .then(response => {
      return response?.powers ?? [];
    });

export const fetchColleges = (fetch: FetchFn, params: FetchCollegesParam): Promise<College[]> =>
  createRepository(fetch, CONTACT_DB_HEADERS)
    .get<FetchCollegesResponse>("/1.0/colleges", params)
    .then(response => mapCollegesDTOToColleges(response?.colleges));

export const fetchDelegations = (fetch: FetchFn, params: FetchDelegationParam): Promise<DelegationDTO[]> =>
  createRepository(fetch, CONTACT_DB_HEADERS)
    .get<{ delegations: DelegationDTO[] }>("/1.0/delegations", params)
    .then(response => response?.delegations);

export const fetchDelegationById = (fetch: FetchFn, id: string): Promise<DelegationDTO> =>
  createRepository(fetch, CONTACT_DB_HEADERS).get<DelegationDTO>(`/1.0/delegations/${id}`);
